
import * as ipxRuntime$rsU44Vpnq6 from '/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/node_modules/@nuxt/image/dist/runtime/providers/ipx'
import * as customimgproxyRuntime$qFqUokCchY from '/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/imgprovider/imgproxy.ts'
import * as noneRuntime$HTEJ6P0gbO from '/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/node_modules/@nuxt/image/dist/runtime/providers/none'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 600,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "none",
  "domains": [
    "famitei.mobi",
    "famitei.jp",
    "www.famitei.co.jp",
    "famitei.co.jp",
    "static.famitei.co.jp",
    "i.ytimg.com",
    "s3.ap-northeast-1.amazonaws.com"
  ],
  "alias": {},
  "densities": [
    1,
    2,
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$rsU44Vpnq6, defaults: {"maxAge":2592000} },
  ['customimgproxy']: { provider: customimgproxyRuntime$qFqUokCchY, defaults: {"baseURL":"https://image.famitei.co.jp"} },
  ['none']: { provider: noneRuntime$HTEJ6P0gbO, defaults: {} }
}
        