import type {
  OperationGeneratorConfig,
  OperationMapper,
  ProviderGetImage,
} from "@nuxt/image";
import { joinURL, withBase } from "ufo";

function createMapper(map: Record<string, string>): OperationMapper {
  return (key?: string) => {
    return key ? map[key] ?? key : map.missingValue;
  };
}

function createOperationsGenerator({
  formatter,
  keyMap,
  joinWith = "/",
  valueMap,
}: OperationGeneratorConfig = {}) {
  if (!formatter) {
    formatter = (key, value: string) => `${key}=${value}`;
  }
  if (keyMap && typeof keyMap !== "function") {
    // @ts-ignore
    keyMap = createMapper(keyMap);
  }
  const map = valueMap ?? {};
  Object.keys(map).forEach((valueKey) => {
    if (typeof map[valueKey] !== "function") {
      // @ts-ignore
      map[valueKey] = createMapper(map[valueKey]);
    }
  });

  return (modifiers: { [key: string]: string } = {}) => {
    const operations = Object.entries(modifiers)
      .filter(([_, value]) => typeof value !== "undefined")
      .map(([key, value]) => {
        const mapper = map[key];
        if (typeof mapper === "function") {
          value = mapper(modifiers[key]);
        }

        key = typeof keyMap === "function" ? keyMap(key) : key;

        return formatter?.(key, value);
      });

    return operations.join(joinWith);
  };
}

const operationsGenerator = createOperationsGenerator({
  keyMap: {
    resize: "rs",
    size: "s",
    fit: "rt",
    width: "w",
    height: "h",
    dpr: "dpr",
    enlarge: "el",
    extend: "ex",
    gravity: "g",
    crop: "c",
    padding: "pd",
    trim: "t",
    rotate: "rot",
    quality: "q",
    maxBytes: "mb",
    background: "bg",
    backgroundAlpha: "bga",
    blur: "bl",
    sharpen: "sh",
    watermark: "wm",
    preset: "pr",
    cacheBuster: "cb",
    stripMetadata: "sm",
    stripColorProfile: "scp",
    autoRotate: "ar",
    filename: "fn",
    format: "f",
  },
  formatter: (key, value) => `${key}:${value}`,
});

export const getImage: ProviderGetImage = (
  src,
  { modifiers = {}, baseURL = "", key, salt } = {},
) => {
  const path = joinURL(
    "/",
    "insecure",
    operationsGenerator(modifiers),
    "plain",
    encodeURIComponent(src),
  );

  return {
    url: withBase(path, baseURL),
  };
};
